<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title><strong>Resultado del proceso de resumen</strong></h1>
  <div class="table-container">


    <mat-card *appRole="['Contratos.GenerarResumenMasivoDetalle']" [hidden]=!verTabla>
      <ngx-spinner [zIndex]="100" name="spresultadoEnvioMasivo" bdColor="rgba(255,255,255,0.8)" size="default"
        color="#009688" type="ball-atom" [fullScreen]="false">
      </ngx-spinner>
      <table mat-table [dataSource]="dataSource" matSort [hidden]=!verTabla class="mat-elevation-z8">

        <!-- Numero de Contrato -->
        <ng-container matColumnDef="numeroContrato">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Numero contrato</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <a [href]="['/contratos', element.idContrato].join('/')" target="_blank"
              class="contrato-link">{{element.numeroContrato}}</a>

          </td>
        </ng-container>

        <!-- Estado Envio Exitoso (Icono) -->
        <ng-container matColumnDef="envioExitoso">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Estado envio</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.envioExitoso" color="primary">check_circle</mat-icon>
            <mat-icon *ngIf="!element.envioExitoso" color="warn">cancel</mat-icon>
          </td>
        </ng-container>

        <!-- Fecha Envio -->
        <ng-container matColumnDef="fechaEnvio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Fecha envio</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.fechaEnvio | date:'yyyy-MM-dd HH:mm:ss'}}</span>
          </td>
        </ng-container>

        <!-- Mensaje con opción de expandir -->
        <ng-container matColumnDef="mensaje">
          <th mat-header-cell *matHeaderCellDef>
            <span>Mensaje</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span [matTooltip]="element.mensaje">{{element.mensaje | slice:0:100}}...</span>
            <button mat-icon-button (click)="openMessageDialog(element.mensajeDetalle)">
              <mat-icon>expand_more</mat-icon>
            </button>
          </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator showFirstLastButtons class="paginator" [hidden]=!verTabla [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
      </mat-paginator>
    </mat-card>



    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0 || dataSource.data == undefined">
        <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
      </span>
    </div>
  </div>


</div>
