import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogVisualizaLiquidacionComponent } from '@views/contratos/form-editar-contrato/dialog-nueva-liquidacion/dialog-visualiza-liquidacion/dialog-visualiza-liquidacion.component';
import { DialogLiquidacionesGeneradasComponent } from '@views/contratos/form-editar-contrato/dialog-nueva-liquidacion/dialog-liquidaciones-generadas/dialog-liquidaciones-generadas.component';


@Component({
  selector: 'app-dialog-generar-liquidaciones',
  templateUrl: './dialog-generar-liquidaciones.component.html',
  styleUrls: ['./dialog-generar-liquidaciones.component.css'],
  providers: [DatePipe]
})
export class DialogGenerarLiquidacionesComponent implements OnInit {

  action: any;
  data: boolean = false;
  procesando: boolean = false
  displayedColumns: string[] = ['select', 'descripcion', 'nroDocumento'];
  dataSource = new MatTableDataSource<PersonaPropietarioDto>();
  selection = new SelectionModel<PersonaPropietarioDto>(true, []);
  tipoLiquidacion = "agrupada"

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor
    (
      private spinner: NgxSpinnerService,
      private snackBar: SnackBarService,
      private toastService: HotToastService,
      private propietariosService: PropietariosService,
      private personasService: PersonasService,
      private loginService: LoginService,
      public dialogRef: MatDialogRef<DialogGenerarLiquidacionesComponent>,
      private datepipe: DatePipe,
      private dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public datos: any
    ) {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder = true,
      toasConfig.position = 'bottom-right'
    this.toastService.defaultConfig = toasConfig
  }

  ngOnInit(): void {
    this.getAllPropietarios()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PersonaPropietarioDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  visualizaLiquidacion() {
    let date = new Date().toDateString()
    let idsPropietarios: number[] = []
    let propietarios = this.selection.selected
    propietarios.forEach(element => {
      idsPropietarios.push(element.id_Propietario)
    });
    this.getSettlement(date, idsPropietarios)

  }

  getSettlement(date: string, idsPropietarios: number[]) {
    this.procesando = true
    this.propietariosService.getSettlement(date, idsPropietarios, 0)
      .pipe(
        this.toastService.observe(
          {
            loading: 'Por favor, aguarde',
            success: (s) => 'Proceso finalizado con exito. ',
            error: (e) => e,
          }
        ))
      .subscribe(
        data => {
          this.toastService.close()
          if (data.settlementsByProperty.length != 0)
            this.openDialogVisualizaLiquidacionComponent(data)
          else
            this.snackBar.showInfo("No se registraron datos.", "Info");
          this.procesando = false
        });
  }


  openDialogVisualizaLiquidacionComponent(settlementResponse: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.autoFocus = false;
    dialogConfig.data = { settlementResponse: settlementResponse, contratoDetailDto: null }

    const dialogRef = this.dialog.open(DialogVisualizaLiquidacionComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  generarLiquidacion() {
    this.procesando = true;
    const usuarioDto = this.loginService.getCurrentUser();
    const idsPropietarios: number[] = [];
    const propietarios = this.selection.selected;
    const agurparPorContrato = this.tipoLiquidacion === "agrupada";

    // Recolectar los IDs de los propietarios seleccionados
    propietarios.forEach(element => {
      idsPropietarios.push(element.id_Propietario);
    });

    // Iniciar el toast de loading
    const toastId = this.toastService.loading('Por favor, aguarde', {
      icon: '⏳',
      autoClose: false
    });

    this.propietariosService.createSettlement(idsPropietarios, usuarioDto.id, 0, agurparPorContrato)
      .subscribe(
        data => {
          this.toastService.success('Proceso finalizado con éxito.');
          toastId.close();

          // Filtrar los propietarios procesados y actualizar la tabla
          this.dataSource.data = this.dataSource.data.filter(
            item => !idsPropietarios.includes(item.id_Propietario)
          );

          // Limpiar selección
          this.selection.clear();

          if (data.liquidacionPorContrato.length !== 0) {
            this.openDialogLiquidacionesGeneradasComponent(data);
          } else {
            this.toastService.info("No se registraron datos.");
          }

          this.procesando = false;
        },
        error => {
          this.toastService.error('Error al procesar la liquidación.');
          toastId.close();
          this.procesando = false;
        }
      );
  }



  openDialogLiquidacionesGeneradasComponent(createSettlementResponse: CreateSettlementResponse) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%";
    dialogConfig.autoFocus = false;
    dialogConfig.data = createSettlementResponse

    const dialogRef = this.dialog.open(DialogLiquidacionesGeneradasComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  getAllPropietarios() {
    this.spinner.show("spPropietarios")
    this.personasService.getAllPersonasPropietarios()
      .subscribe(
        data => {
          this.spinner.hide("spPropietarios")
          this.dataSource.data = data.filter(x => x.id_Propietario != 0 && x.titular == true)
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spPropietarios")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }

}
